/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cognito_identity_pool_id": "us-east-1:610db8fa-6bd8-4bc8-83b0-e69a27f4f385",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_zvj88Aqq4",
    "aws_user_pools_web_client_id": "6iqtsud0uenf2ij8otbjtlpk7l",
    "oauth": {}
};


export default awsmobile;
